<template>
    <div class="text-right mt-1">
        <b-button variant="primary" @click="getQuestions">
            <span class="text-nowrap">Save</span>
        </b-button>
        <b-modal
            id="save-series-question-modal"
            hide-footer
            size="xl"
            centered
            title="Save Series Questions"
        >
            <div class="modal-content">
                <div class="modal-body">
                    <div class="row p-1" style="row-gap: 25px">
                        <div
                            class="col-4 position-relative"
                            v-for="(question, questionIndex) in questions"
                            :key="questionIndex + 'question'"
                            v-if="isUpdated(question)"
                        >
                            <div class="d-flex">
                                Q
                                <div
                                    v-html="
                                        isChangeQuestionNo(
                                            question._id,
                                            question.questionNo
                                        )
                                    "
                                    v-if="question._id"
                                ></div>
                                <span v-else>{{ question.questionNo }}</span> :
                                {{ question._id ? "Update" : "New" }}
                            </div>
                            <div class="save-question-background-image">
                                <div
                                    class="
                                        d-flex
                                        flex-column
                                        justify-content-between
                                        height-300
                                    "
                                >
                                    <div class="d-flex justify-content-center">
                                        <div
                                            v-if="question.title"
                                            class="
                                                text-white
                                                position-relative
                                                curser-pointer
                                                display-line-3
                                            "
                                            @click="
                                                textToSpeak(question.titleTts)
                                            "
                                            style="
                                                background-color: #0d5cab;
                                                border-bottom-left-radius: 5px;
                                                border-bottom-right-radius: 5px;
                                                padding: 10px;
                                                max-width: 90%;
                                                min-width: 30%;
                                            "
                                        >
                                            <div
                                                v-html="
                                                    isChangeQuestionTitle(
                                                        question._id,
                                                        question.title
                                                    )
                                                "
                                                v-if="question._id"
                                            ></div>
                                            <div v-else>
                                                {{ question.title }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="d-flex justify-content-center">
                                        <div class="d-flex flex-column w-75">
                                            <div
                                                v-for="(
                                                    desc, descIndex
                                                ) in question.descriptions"
                                                :key="descIndex + 'desc'"
                                            >
                                                <div
                                                    v-if="
                                                        (desc.title != '' &&
                                                            desc.title !=
                                                                null) ||
                                                        (desc.image &&
                                                            desc.image.length >
                                                                0)
                                                    "
                                                    class="
                                                        text-dark text-center
                                                        mt-1
                                                        bg-white
                                                        curser-pointer
                                                        display-line-3
                                                        d-flex
                                                        justify-content-center
                                                    "
                                                    style="
                                                        padding: 10px;
                                                        border-radius: 5px;
                                                        font-weight: 500;
                                                    "
                                                    @click="
                                                        textToSpeak(desc.tts)
                                                    "
                                                >
                                                    <div
                                                        v-html="
                                                            isChangeQuestionDescription(
                                                                question._id,
                                                                descIndex,
                                                                desc.title
                                                            )
                                                        "
                                                        v-if="question._id"
                                                    ></div>
                                                    <div v-else>
                                                        {{ desc.title }}
                                                    </div>
                                                    <div
                                                        v-for="img in desc.image"
                                                    >
                                                        <img
                                                            :src="img.url"
                                                            height="30"
                                                            :alt="img.name"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        class="
                                            d-flex
                                            justify-content-center
                                            w-100
                                            gap-25
                                            mb-1
                                        "
                                        style="flex-wrap: wrap"
                                    >
                                        <!-- <div v-for="(
                                                opt, optIndex
                                            ) in question.options" :key="optIndex + 'opt'">
                                            <div v-if="opt.title != '' && opt.title != null || opt.image" class="
                                                    text-dark text-center
                                                    bg-white
                                                    position-relative
                                                    curser-pointer
                                                    display-line-3
                                                    d-flex
                                                    justify-content-center
                                                " style="
                                                    padding: 5px 0px;
                                                    border-radius: 5px;
                                                    font-weight: 500;
                                                    width: 100px;
                                                " @click="textToSpeak(opt.tts)">
                                                <div v-html="isChangeQuestionOption(question._id, optIndex, opt.title)"
                                                    v-if="question._id"></div>
                                                <div v-else>
                                                    {{ opt.title }}
                                                </div>
                                                <img v-if="opt.image" :src="opt.image" height="30" :alt="opt.title" />
                                                <div class="position-absolute" style="
                                                        top: -2px;
                                                        right: 3px;
                                                    ">
                                                    <feather-icon v-if="opt.correct" icon="CheckIcon" size="14" class="
                                                            qu-icon-grey
                                                            text-success
                                                        " />
                                                </div>
                                            </div>
                                        </div> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <b-button @click="$emit('saveQuestions')" variant="primary">
                        Save
                    </b-button>
                </div>
            </div>
        </b-modal>
    </div>
</template>

<script>
import {
    BButton,
    BFormInput,
    BFormGroup,
    BAlert,
    BSpinner,
} from "bootstrap-vue";
import axios from "axios";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
export default {
    components: {
        BButton,
        BFormInput,
        BFormGroup,
        BAlert,
        BSpinner,
        ToastificationContent,
    },
    data() {
        return {
            errorMsg: "",
            loading: false,
            alertMessageToast: "",
            updateQuestions: [],
            newQuestions: [],
            utterance: null,
        };
    },
    methods: {
        isChangeQuestionNo(id, No) {
            const orgObj = this.originalQuestions.find((obj) => obj._id === id);
            const objTitle =
                orgObj.questionNo === null ? "" : orgObj.questionNo;
            if (orgObj) {
                if (objTitle === No) {
                    return No;
                } else {
                    return `<del class='text-danger'>${objTitle}</del><span class='text-success'>${No}</span>`;
                }
            }
        },
        isChangeQuestionTitle(id, title) {
            const orgObj = this.originalQuestions.find((obj) => obj._id === id);
            const objTitle = orgObj.title === null ? "" : orgObj.title;
            if (orgObj) {
                if (objTitle === title) {
                    return title;
                } else {
                    return `<del class='text-danger'>${objTitle}</del><div class='text-success'>${title}</div>`;
                }
            }
        },
        isChangeQuestionDescription(id, index, title) {
            const orgObj = this.originalQuestions.find((obj) => obj._id === id);
            if (orgObj && orgObj.descriptions && orgObj.descriptions[index]) {
                const descTitle = orgObj.descriptions[index].title || "";
                if (descTitle === title) {
                    return title;
                } else {
                    if (title) {
                        return `<del class='text-danger'>${descTitle}</del><div class='text-success'>${title}</div>`;
                    } else {
                        return `<del class='text-danger'>${descTitle}</del>`;
                    }
                }
            } else {
                return title;
            }
        },
        isChangeQuestionOption(id, index, title) {
            const orgObj = this.originalQuestions.find((obj) => obj._id === id);
            if (orgObj && orgObj.options && orgObj.options[index]) {
                const optTitle = orgObj.options[index].title || "";
                if (optTitle === title) {
                    return title;
                } else {
                    if (title) {
                        return `<del class='text-danger'>${optTitle}</del><div class='text-success'>${title}</div>`;
                    } else {
                        return `<del class='text-danger'>${optTitle}</del>`;
                    }
                }
            } else {
                return title;
            }
        },
        isUpdated(question) {
            if (question._id) {
                const found = this.updateQuestions.some(
                    (updatedQuestion) => updatedQuestion._id === question._id
                );

                if (found) {
                    return true;
                } else {
                    return false;
                }
            }
            return true;
        },
        textToSpeak(text) {
            let speakText = text === null ? "" : text;
            if ("speechSynthesis" in window) {
                if (this.utterance) {
                    window.speechSynthesis.cancel();
                }
                this.utterance = new SpeechSynthesisUtterance(speakText);
                window.speechSynthesis.speak(this.utterance);
            } else {
                alert("Sorry, your browser does not support text-to-speech.");
            }
        },
        async getQuestions() {
            this.updateQuestions = await this.updateQuestionsChanges();
            this.newQuestions = await this.newQuestion();

            if (
                this.updateQuestions.length === 0 &&
                this.newQuestions.length === 0
            ) {
                return "No changes or new questions found.";
            } else {
                this.$bvModal.show("save-series-question-modal");
            }
        },
    },
    props: [
        "updateQuestionsChanges",
        "newQuestion",
        "questions",
        "originalQuestions",
    ],
};
</script>
