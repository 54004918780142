var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-right mt-1"},[_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.getQuestions}},[_c('span',{staticClass:"text-nowrap"},[_vm._v("Save")])]),_c('b-modal',{attrs:{"id":"save-series-question-modal","hide-footer":"","size":"xl","centered":"","title":"Save Series Questions"}},[_c('div',{staticClass:"modal-content"},[_c('div',{staticClass:"modal-body"},[_c('div',{staticClass:"row p-1",staticStyle:{"row-gap":"25px"}},_vm._l((_vm.questions),function(question,questionIndex){return (_vm.isUpdated(question))?_c('div',{key:questionIndex + 'question',staticClass:"col-4 position-relative"},[_c('div',{staticClass:"d-flex"},[_vm._v(" Q "),(question._id)?_c('div',{domProps:{"innerHTML":_vm._s(
                                    _vm.isChangeQuestionNo(
                                        question._id,
                                        question.questionNo
                                    )
                                )}}):_c('span',[_vm._v(_vm._s(question.questionNo))]),_vm._v(" : "+_vm._s(question._id ? "Update" : "New")+" ")]),_c('div',{staticClass:"save-question-background-image"},[_c('div',{staticClass:"\n                                    d-flex\n                                    flex-column\n                                    justify-content-between\n                                    height-300\n                                "},[_c('div',{staticClass:"d-flex justify-content-center"},[(question.title)?_c('div',{staticClass:"\n                                            text-white\n                                            position-relative\n                                            curser-pointer\n                                            display-line-3\n                                        ",staticStyle:{"background-color":"#0d5cab","border-bottom-left-radius":"5px","border-bottom-right-radius":"5px","padding":"10px","max-width":"90%","min-width":"30%"},on:{"click":function($event){return _vm.textToSpeak(question.titleTts)}}},[(question._id)?_c('div',{domProps:{"innerHTML":_vm._s(
                                                _vm.isChangeQuestionTitle(
                                                    question._id,
                                                    question.title
                                                )
                                            )}}):_c('div',[_vm._v(" "+_vm._s(question.title)+" ")])]):_vm._e()]),_c('div',{staticClass:"d-flex justify-content-center"},[_c('div',{staticClass:"d-flex flex-column w-75"},_vm._l((question.descriptions),function(desc,descIndex){return _c('div',{key:descIndex + 'desc'},[(
                                                    (desc.title != '' &&
                                                        desc.title !=
                                                            null) ||
                                                    (desc.image &&
                                                        desc.image.length >
                                                            0)
                                                )?_c('div',{staticClass:"\n                                                    text-dark text-center\n                                                    mt-1\n                                                    bg-white\n                                                    curser-pointer\n                                                    display-line-3\n                                                    d-flex\n                                                    justify-content-center\n                                                ",staticStyle:{"padding":"10px","border-radius":"5px","font-weight":"500"},on:{"click":function($event){return _vm.textToSpeak(desc.tts)}}},[(question._id)?_c('div',{domProps:{"innerHTML":_vm._s(
                                                        _vm.isChangeQuestionDescription(
                                                            question._id,
                                                            descIndex,
                                                            desc.title
                                                        )
                                                    )}}):_c('div',[_vm._v(" "+_vm._s(desc.title)+" ")]),_vm._l((desc.image),function(img){return _c('div',[_c('img',{attrs:{"src":img.url,"height":"30","alt":img.name}})])})],2):_vm._e()])}),0)]),_c('div',{staticClass:"\n                                        d-flex\n                                        justify-content-center\n                                        w-100\n                                        gap-25\n                                        mb-1\n                                    ",staticStyle:{"flex-wrap":"wrap"}})])])]):_vm._e()}),0)]),_c('div',{staticClass:"modal-footer"},[_c('b-button',{attrs:{"variant":"primary"},on:{"click":function($event){return _vm.$emit('saveQuestions')}}},[_vm._v(" Save ")])],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }